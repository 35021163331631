/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { ColumnGroup } from 'primereact/columngroup';
import Highcharts from 'highcharts';
import FieldTag from 'components/Controls/FieldTag.jsx'
import IndividualIncentiveCompensationDetailByInvoice from './IndividualIncentiveCompensationDetailByInvoice.jsx';
import IncentiveProgramsGoalsSalesRep from '../IncentiveProgramsGoals/IncentiveProgramsGoalsSalesRep.jsx';
import { Dialog } from 'primereact/dialog';

const IndividualIncetiveCompensation = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['indIncCompensation', 'common']);

  const [errors, setErrors] = useState({});  
  const [InsentiveCompensation, setInsentiveCompensation] = useState([]);
  const [InsentiveCompensationPM, setInsentiveCompensationPM] = useState([]);
  const [InsentiveCompensationTons, setInsentiveCompensationTons] = useState([]);
  const [InsentiveCompensationSpread, setInsentiveCompensationSpread] = useState([]);
  const [InsentiveCompensationStrategicTons, setInsentiveCompensationStrategicTons] = useState([]);
  const [InsentiveCompensationTeamProfitServe, setInsentiveCompensationTeamProfitServe] = useState([]);
  const [InsentiveCompensationTotalPay, setInsentiveCompensationTotalPay] = useState([]);
  const [showTonsAllColumns, setshowTonsAllColumns] = useState(false);
  const [showSpreadAllColumns, setshowSpreadAllColumns] = useState(false);
  const [showStrategicTonsAllColumns, setshowStrategicTonsAllColumns] = useState(false);
  const [showTotalPayAllColumns, setshowTotalPayAllColumns] = useState(false);
  const [InsentiveCompensationMonthsStatus, setInsentiveCompensationMonthsStatus] = useState({});  
  const [EsDetailInvoice, setEsDetailInvoice] = useState(false);
  const [EsViewMonthlyGoals, setEsViewMonthlyGoals] = useState(false);
  const [EsTienePermisoInfoCondidencial, setEsTienePermisoInfoCondidencial] = useState(false);
  const [InsentiveCompensationStrategicTonsV2, setInsentiveCompensationStrategicTonsV2] = useState([]);
  const [InsentiveCompensationRevenueExworksV2, setInsentiveCompensationRevenueExworksV2] = useState([]);
  const [InsentiveCompensationCustomerRetentionV2, setInsentiveCompensationCustomerRetentionV2] = useState([]);
  const [showStrategicTonsV2AllColumns, setshowStrategicTonsV2AllColumns] = useState(false);
  const [showRevenueExworksV2AllColumns, setshowRevenueExworksV2AllColumns] = useState(false);
  const [showCustomerRetentionV2AllColumns, setshowCustomerRetentionV2AllColumns] = useState(false);

  useEffect(() => {
    // obtenerDivisiones();
    // obtenerGruposEstadisticos3();
  }, []);

  useEffect(() => {
    obtieneIncentiveSalesRepInfo();
  }, [props.ClaAnio, props.ClaAgenteVentas, props.ClaMes]);

  const obtieneIncentiveSalesRepInfo = () => {
    let getIndividualIncetiveCompensation = `${config.UrlApiProject}SalesPerformanceSales/GetIndividualIncetiveCompensation`;

    if(props.ClaAnio>=2025){
      getIndividualIncetiveCompensation = `${config.UrlApiProject}SalesPerformanceSales/GetIndividualIncetiveCompensationV2`;
    }

    const filters = {
      ClaAnio : props.ClaAnio,
      ClaMes : props.ClaMes,
      ClaAgenteVentas : props.ClaAgenteVentas
    }

    callApi(getIndividualIncetiveCompensation, 'POST', filters, (response) => {
      const InsentiveCompensationMonths = response.data.InsentiveCompensationMonthsStatus.length > 0 ? response.data.InsentiveCompensationMonthsStatus[0] :{} 
      setInsentiveCompensationMonthsStatus(InsentiveCompensationMonths);  
      setInsentiveCompensation(response.data.InsentiveCompensation);
      setInsentiveCompensationPM(response.data.InsentiveCompensationPM);
      setInsentiveCompensationTeamProfitServe(response.data.InsentiveCompensationTeamProfitServe);
      setInsentiveCompensationTotalPay(response.data.InsentiveCompensationTotalPay);   
      setEsTienePermisoInfoCondidencial(response.data.InsentiveCompensationAccess.EsTienePermisosVerInfoConfidencial);   
      if(props.ClaAnio>=2025){
          setInsentiveCompensationStrategicTonsV2(response.data.InsentiveCompensationStrategicTons);
          setInsentiveCompensationRevenueExworksV2(response.data.InsentiveCompensationRevenueExworks);
          setInsentiveCompensationCustomerRetentionV2(response.data.InsentiveCompensationCustomerRetention);
      }else{
          setInsentiveCompensationTons(response.data.InsentiveCompensationTons);
          setInsentiveCompensationSpread(response.data.InsentiveCompensationSpread);
          setInsentiveCompensationStrategicTons(response.data.InsentiveCompensationStrategicTons);
      }
    });
  };

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows.length > 0) {
      rows.forEach((item, index) => {
        if (item.BajaLogica === 0 || item.BajaLogica === undefined) {
          item.BajaLogica = 0;
          item.RowIndex = index;
          activeRows.push({ ...item });
        }
      });
    } else {
      return [];
    }
    return activeRows;
  };

  const WeighttingColumn = (row) => {      
    return (<>{ row.Weightting === null ? '' : row.Weightting.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%</>);
  }

  const TargetColumn = (row) => {  
    return (<>${row.Target === null || row.Target === undefined ? '' : row.Target.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</>);
  }

  const RederFormatColumnText = (columnName, row) => {      
    let PreviewInformationClass = ''; 

    if (InsentiveCompensationMonthsStatus[columnName] !== undefined){
      if (InsentiveCompensationMonthsStatus[columnName] === 1){ 
        PreviewInformationClass ='light-gray-color'
      }
    }

    if (row.TipoFormato === 2) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `${row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })  }%`}</span>);    
    }
    if (row.TipoFormato === 3) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `$${  Number(row[columnName]).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}</span>);    
    }  

    if (row.TipoFormato === 4) {
      return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>);    
    }  

    if (row.TipoFormato === 5) {
      return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>);    
    }  

    if (row.TipoFormato === 6) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `$${  Number(row[columnName]).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`}</span>);    
    }  
    
    if (row.TipoFormato === 7) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `${row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })  }%`}</span>);    
    }

    return (<span className={PreviewInformationClass}>{row[columnName]}</span>);
  }

  const RederFormatColumn = (columnName, row) => {      
     
    let PreviewInformationClass = ''; 

    // console.log(InsentiveCompensationMonthsStatus[columnName]);
    if (InsentiveCompensationMonthsStatus[columnName] !== undefined){
      if (InsentiveCompensationMonthsStatus[columnName] === 1){ 
        PreviewInformationClass ='light-gray-color'
      }
    }

    if (row.TipoFormato === 2) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `${row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })  }%`}</span>);    
    }
    if (row.TipoFormato === 3) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `$${  Number(row[columnName]).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}</span>);    
    }  

    if (row.TipoFormato === 4) {
      return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>);    
    }  

    if (row.TipoFormato === 5) {
      return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}</span>);    
    }  

    if (row.TipoFormato === 6) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `$${  Number(row[columnName]).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`}</span>);    
    }  
    if (row.TipoFormato === 7) {
        return (<span className={PreviewInformationClass}>{ row[columnName] === null ? '' : `${row[columnName].toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })  }%`}</span>);    
    }

    return (<span className={PreviewInformationClass}>{row[columnName]}</span>);
  }

  const percentageOfTarget = (row) => {      
    return (<>{ row.OfTarget === null || row.OfTarget === undefined  ? '' : row.OfTarget.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%</>);
  }

  const headerTons  = ( 
    <>  
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12"> 
          <FieldTag 
            label={showTonsAllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
            className="info" 
            onClick={() => setshowTonsAllColumns(!showTonsAllColumns)}
          />   
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">
          
          
        </div>
      </div>  
     
    </>
    )  
  
  const headerStrategicTonsV2  = ( 
    <>  
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12"> 
          <FieldTag 
            label={showStrategicTonsV2AllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
            className="info" 
            onClick={() => setshowStrategicTonsV2AllColumns(!showStrategicTonsV2AllColumns)}
          />   
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">
            
            
        </div>
      </div>  
       
    </>
    )  
  
  const headerRevenueExworksV2  = ( 
    <>  
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12"> 
          <FieldTag 
            label={showRevenueExworksV2AllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
            className="info" 
            onClick={() => setshowRevenueExworksV2AllColumns(!showRevenueExworksV2AllColumns)}
          />   
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">
          
          
        </div>
      </div>  
     
    </>
    )  

  const headerCustomerRetentionV2  = ( 
    <>  
      <div className="row">
        <div className="col-12 col-lg-6 col-sm-12"> 
          <FieldTag 
            label={showCustomerRetentionV2AllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
            className="info" 
            onClick={() => setshowCustomerRetentionV2AllColumns(!showCustomerRetentionV2AllColumns)}
          />   
        </div>
        <div className="col-12 col-lg-6 col-sm-12 text-right">
          
          
        </div>
      </div>  
     
    </>
    )  

    const headerSpread  = ( 
      <>  
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12"> 
            <FieldTag 
              label={showSpreadAllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
              className="info" 
              onClick={() => setshowSpreadAllColumns(!showSpreadAllColumns)}
            />   
          </div>
          <div className="col-12 col-lg-6 col-sm-12 text-right">
            
            
          </div>
        </div>  
       
      </>
      )  
    
    const headerStrategicTons  = ( 
      <>  
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12"> 
            <FieldTag 
              label={showStrategicTonsAllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
              className="info" 
              onClick={() => setshowStrategicTonsAllColumns(!showStrategicTonsAllColumns)}
            />   
          </div>
          <div className="col-12 col-lg-6 col-sm-12 text-right">
              
              
          </div>
        </div>  
         
      </>
        )  

        const headerTotalPay  = ( 
          <>  
            <div className="row">
              <div className="col-12 col-lg-6 col-sm-12"> 
                <FieldTag 
                  label={showTotalPayAllColumns ? t('indIncCompensation:HideMonths') : t('indIncCompensation:ShowMonths')}
                  className="info" 
                  onClick={() => setshowTotalPayAllColumns(!showTotalPayAllColumns)}
                />   
              </div>
              <div className="col-12 col-lg-6 col-sm-12 text-right">
                  
                  
              </div>
            </div>  
             
          </>
            )  
            const onChangeEsDetailInvoice = () => {
                setEsDetailInvoice(true);   
            };

  const onClickViewMonthlyGoals = () =>
  {
    setEsViewMonthlyGoals(true);   
  }         

  const onHideDetailInvoice = () => {
    setEsDetailInvoice(false);    
  };

  const NumberEditor = (columnName, props, TypeOpc) => {
    if (props.rowData.RowIndex === 0) {
      if (InsentiveCompensationMonthsStatus[columnName] !== undefined) {
        if (InsentiveCompensationMonthsStatus[columnName] === 1) {
          return (
            <div className="p-fluid">
              <FieldNumber
                name={columnName}
                value={props.rowData[columnName]}
                onChange={(e) => onNumberChange(props, e.target.value, TypeOpc)}
                min={-99999999.99}
                max={99999999.99}
                minFractionDigits={2}
                maxFractionDigits={2}
              />
            </div>
          ); 
        }      
        return RederFormatColumn(columnName, props.rowData);        
      }
    }else{
      return RederFormatColumn(columnName, props.rowData);        
    }
  }; 

  const onNumberChange = (props, value, TypeOpc) => {
    let updatedRow = [];
    switch (TypeOpc) {
      case 1:        
        updatedRow = [...InsentiveCompensationTons];
        updatedRow[props.rowData.RowIndex][props.field] = value;    
        setInsentiveCompensationTons(updatedRow);    
        break;
        case 2:        
        updatedRow = [...InsentiveCompensationSpread];
        updatedRow[props.rowData.RowIndex][props.field] = value;    
        setInsentiveCompensationSpread(updatedRow);    
        break;
        case 3:        
        updatedRow = [...InsentiveCompensationStrategicTons];
        updatedRow[props.rowData.RowIndex][props.field] = value;    
        setInsentiveCompensationStrategicTons(updatedRow);    
        break;
        case 4:        
        updatedRow = [...InsentiveCompensationTeamProfitServe];
        updatedRow[props.rowData.RowIndex][props.field] = value;    
        setInsentiveCompensationTeamProfitServe(updatedRow);    
        break;
    
      default:
        break;
    }    
  };

  
  const recalculeIncentiveSalesRepInfo = () => {
    const infoSimulacion = [];

    const rowInsentiveCompensation = {
      Tipo: 1,
      EneroPresupuesto: InsentiveCompensationTons[0].Ene,
      FebreroPresupuesto: InsentiveCompensationTons[0].Feb,
      MarzoPresupuesto: InsentiveCompensationTons[0].Mar,
      AbrilPresupuesto: InsentiveCompensationTons[0].Abr,
      MayoPresupuesto: InsentiveCompensationTons[0].May,
      JunioPresupuesto: InsentiveCompensationTons[0].Jun,
      JulioPresupuesto: InsentiveCompensationTons[0].Jul,
      AgostoPresupuesto: InsentiveCompensationTons[0].Ago,
      SeptiembrePresupuesto: InsentiveCompensationTons[0].Sep,
      OctubrePresupuesto: InsentiveCompensationTons[0].Oct,
      NoviembrePresupuesto: InsentiveCompensationTons[0].Nov,
      DiciembrePresupuesto: InsentiveCompensationTons[0].Dic,
    }
    infoSimulacion.push(rowInsentiveCompensation);

    const rowInsentiveCompensationSpread = {
      Tipo: 2,
      EneroPresupuesto: InsentiveCompensationSpread[0].Ene,
      FebreroPresupuesto: InsentiveCompensationSpread[0].Feb,
      MarzoPresupuesto: InsentiveCompensationSpread[0].Mar,
      AbrilPresupuesto: InsentiveCompensationSpread[0].Abr,
      MayoPresupuesto: InsentiveCompensationSpread[0].May,
      JunioPresupuesto: InsentiveCompensationSpread[0].Jun,
      JulioPresupuesto: InsentiveCompensationSpread[0].Jul,
      AgostoPresupuesto: InsentiveCompensationSpread[0].Ago,
      SeptiembrePresupuesto: InsentiveCompensationSpread[0].Sep,
      OctubrePresupuesto: InsentiveCompensationSpread[0].Oct,
      NoviembrePresupuesto: InsentiveCompensationSpread[0].Nov,
      DiciembrePresupuesto: InsentiveCompensationSpread[0].Dic,
    }
    infoSimulacion.push(rowInsentiveCompensationSpread);

    const rowInsentiveCompensationStrategicTons = {
      Tipo: 3,
      EneroPresupuesto: InsentiveCompensationStrategicTons[0].Ene,
      FebreroPresupuesto: InsentiveCompensationStrategicTons[0].Feb,
      MarzoPresupuesto: InsentiveCompensationStrategicTons[0].Mar,
      AbrilPresupuesto: InsentiveCompensationStrategicTons[0].Abr,
      MayoPresupuesto: InsentiveCompensationStrategicTons[0].May,
      JunioPresupuesto: InsentiveCompensationStrategicTons[0].Jun,
      JulioPresupuesto: InsentiveCompensationStrategicTons[0].Jul,
      AgostoPresupuesto: InsentiveCompensationStrategicTons[0].Ago,
      SeptiembrePresupuesto: InsentiveCompensationStrategicTons[0].Sep,
      OctubrePresupuesto: InsentiveCompensationStrategicTons[0].Oct,
      NoviembrePresupuesto: InsentiveCompensationStrategicTons[0].Nov,
      DiciembrePresupuesto: InsentiveCompensationStrategicTons[0].Dic,
    }
    infoSimulacion.push(rowInsentiveCompensationStrategicTons);

    const rowInsentiveCompensationTeamProfitServe = {
      Tipo: 4,
      EneroPresupuesto: 0,
      FebreroPresupuesto: 0,
      MarzoPresupuesto: 0,
      AbrilPresupuesto: 0,
      MayoPresupuesto: 0,
      JunioPresupuesto: 0,
      JulioPresupuesto: 0,
      AgostoPresupuesto: 0,
      SeptiembrePresupuesto: 0,
      OctubrePresupuesto: 0,
      NoviembrePresupuesto: 0,
      DiciembrePresupuesto: InsentiveCompensationStrategicTons[0].Total,
    }
    infoSimulacion.push(rowInsentiveCompensationTeamProfitServe);

    console.log(infoSimulacion);

    const paramsService = {
      ClaAnio: props.ClaAnio,
      ClaAgenteVentas: props.ClaAgenteVentas,
      ClaMes: props.ClaMes,
      RecalculateListDTO: infoSimulacion,

    };
  const urlWebService = `${config.UrlApiProject}SalesPerformanceSales/GetRecalculateIndividualIncetiveCompensation`;
  callApi(urlWebService, 'POST', paramsService, (response) => { 
      const InsentiveCompensationMonths = response.data.InsentiveCompensationMonthsStatus.length > 0 ? response.data.InsentiveCompensationMonthsStatus[0] :{} 
      setInsentiveCompensationMonthsStatus(InsentiveCompensationMonths);  
      setInsentiveCompensation(response.data.InsentiveCompensation);
      setInsentiveCompensationPM(response.data.InsentiveCompensationPM);
      setInsentiveCompensationTons(response.data.InsentiveCompensationTons);
      setInsentiveCompensationSpread(response.data.InsentiveCompensationSpread);
      setInsentiveCompensationStrategicTons(response.data.InsentiveCompensationStrategicTons);
      setInsentiveCompensationTeamProfitServe(response.data.InsentiveCompensationTeamProfitServe);
      setInsentiveCompensationTotalPay(response.data.InsentiveCompensationTotalPay);       
  });  
  };
          
  return (
    <> 
      <Row>
        <Col>
          <Card>
            <CardBody> 
              {
                  props.ClaAnio<2025 &&
                  (
                  <Row className='pb-1'>
                    <Col className="text-right ">                  
                      <FieldButton
                        label={t('salesPerformance:ViewMonthlyGoals')}
                        className="p-button-rounded p-button-secondary"
                        onClick={() => onClickViewMonthlyGoals()}
                      />
                  &nbsp;
                      <FieldButton
                        label={t('salesPerformance:Recalculate')}
                        className="p-button-rounded p-button-success"
                        onClick={() => recalculeIncentiveSalesRepInfo()}
                      />&nbsp;
                      <FieldButton
                        label={t('salesPerformance:ViewDetailInvoice')}
                        className="p-button-rounded p-button-info"
                        onClick={() => onChangeEsDetailInvoice()}
                      />
                    </Col>
                  </Row>  
                  )
                 }    
              <Row className="align-items-start ">
                <Col lg={4} md={4} sm={12} xs={12}>
                  {
                  EsTienePermisoInfoCondidencial &&
                  (
                  <FieldDataTable
                    value={InsentiveCompensation}
                    scrollable
                    className="editable-cells-table"
                    editMode="cell"
                    selectionMode="single"
                    paginator={false}
                  >
                    <Column
                      field="NomIndividualIncetive"
                      columnKey="NomIndividualIncetive"
                      header={t('indIncCompensation:NomIndividualIncetive')}
                      headerStyle={{ width: '220px' }}
                      className="table-header-background-ligth-green"
                    >
                    </Column>
                    <Column
                      field="ValueIndividualIncetive"
                      columnKey="ValueIndividualIncetive"
                      header=""
                      headerStyle={{ width: '150px' }}
                      className="table-header-background-dark-green"
                      body={(rowData, props) => RederFormatColumnText("ValueIndividualIncetive", rowData)}
                    >
                    </Column>
                  </FieldDataTable>   
                  )
                 }
                                    
                </Col>
                <Col lg={8} md={8} sm={12} xs={12}>
                  <FieldDataTable
                    value={InsentiveCompensationPM}
                    scrollable
                    className="editable-cells-table"
                    editMode="cell"
                    selectionMode="single"
                    paginator={false}
                  >
                    <Column
                      field="PlanMeasure"
                      columnKey="PlanMeasure"
                      header={t('indIncCompensation:PlanMeasure')}
                      headerStyle={{ width: '400px' }}
                      className="table-header-background-ligth-green"
                    >
                    </Column>
                    <Column
                      field="Weightting"
                      columnKey="Weightting"
                      header={t('indIncCompensation:Weightting')}
                      headerStyle={{ width: '110px' }}
                      body={WeighttingColumn}
                      className="table-header-background-dark-green"
                    >
                    </Column>
                    {
                        EsTienePermisoInfoCondidencial && (
                          <Column
                            field="Target"
                            columnKey="Target"
                            header={t('indIncCompensation:Target')}
                            body={TargetColumn}
                            headerStyle={{ width: '110px' }}
                            className="table-header-background-dark-green"
                          >
                          </Column>
                        )
                    }
                  </FieldDataTable>
                </Col>
              </Row>
              {
                props.ClaAnio<2025 ? (
                  <>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationTons)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerTons}
                        >
                          <Column
                            field="NameTons"
                            columnKey="NameTons"
                            header={t('indIncCompensation:Tons')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                          showTonsAllColumns && (
                          <Column
                            field="Dic"
                            columnKey="Dic"
                            header={t('indIncCompensation:Dic')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-ligth-green"
                            body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                            editor={(props) => NumberEditor("Dic", props, 1)}
                          >
                          </Column>
                          )
                        }
                          {
                          showTonsAllColumns && (
                          <Column
                            field="Ene"
                            columnKey="Ene"
                            header={t('indIncCompensation:Ene')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-ligth-green"
                            body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                            editor={(props) => NumberEditor("Ene", props, 1)}
                          >
                          </Column>
                        )
                        }
                          {
                            showTonsAllColumns && (
                            <Column
                              field="Feb"
                              columnKey="Feb"
                              header={t('indIncCompensation:Feb')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                              editor={(props) => NumberEditor("Feb", props, 1)}
                            >
                            </Column>
                          )
                        }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                          showTonsAllColumns && (
                          <Column
                            field="Mar"
                            columnKey="Mar"
                            header={t('indIncCompensation:Mar')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-ligth-green"
                            body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                            editor={(props) => NumberEditor("Mar", props, 1)}
                          >
                          </Column>
                        )
                        }
                          {
                          showTonsAllColumns && (
                            <Column
                              field="Abr"
                              columnKey="Abr"
                              header={t('indIncCompensation:Abr')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                              editor={(props) => NumberEditor("Abr", props, 1)}
                            >
                            </Column>
                        )
                        }
                          {
                          showTonsAllColumns && (
                            <Column
                              field="May"
                              columnKey="May"
                              header={t('indIncCompensation:May')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("May", rowData)}
                              editor={(props) => NumberEditor("May", props, 1)}
                            >
                            </Column>
                        )
                        }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                            showTonsAllColumns && (
                            <Column
                              field="Jun"
                              columnKey="Jun"
                              header={t('indIncCompensation:Jun')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                              editor={(props) => NumberEditor("Jun", props, 1)}
                            >
                            </Column>
                        )
                        }
                          {
                        showTonsAllColumns && (
                        <Column
                          field="Jul"
                          columnKey="Jul"
                          header={t('indIncCompensation:Jul')}
                          headerStyle={{ width: '90px' }}
                          className="table-header-background-ligth-green"
                          body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                          editor={(props) => NumberEditor("Jul", props, 1)}
                        >
                        </Column>
                        )
                        }
                          {
                            showTonsAllColumns && (
                            <Column
                              field="Ago"
                              columnKey="Ago"
                              header={t('indIncCompensation:Ago')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                              editor={(props) => NumberEditor("Ago", props, 1)}
                            >
                            </Column>
                          )
                        }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                  showTonsAllColumns && (
                  <Column
                    field="Sep"
                    columnKey="Sep"
                    header={t('indIncCompensation:Sep')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                    editor={(props) => NumberEditor("Sep", props, 1)}
                  >
                  </Column>
                )
              }
                          {
                  showTonsAllColumns && (
                  <Column
                    field="Oct"
                    columnKey="Oct"
                    header={t('indIncCompensation:Oct')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                    editor={(props) => NumberEditor("Oct", props, 1)}
                  >
                  </Column>
                )
              }
                          {
                  showTonsAllColumns && (
                  <Column
                    field="Nov"
                    columnKey="Nov"
                    header={t('indIncCompensation:Nov')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                    editor={(props) => NumberEditor("Nov", props, 1)}
                  >
                  </Column> 
                )
                }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationSpread)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerSpread}
                        >
                          <Column
                            field="NameSpread"
                            columnKey="NameSpread"
                            header={t('indIncCompensation:Spread')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Dic"
                    columnKey="Dic"
                    header={t('indIncCompensation:Dic')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                    editor={(props) => NumberEditor("Dic", props, 2)}
                  >
                  </Column>
                  )
                }
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Ene"
                    columnKey="Ene"
                    header={t('indIncCompensation:Ene')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                    editor={(props) => NumberEditor("Ene", props, 2)}
                  >
                  </Column>
                )
                }
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Feb"
                    columnKey="Feb"
                    header={t('indIncCompensation:Feb')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                    editor={(props) => NumberEditor("Feb", props, 2)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Mar"
                    columnKey="Mar"
                    header={t('indIncCompensation:Mar')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                    editor={(props) => NumberEditor("Mar", props, 2)}
                  >
                  </Column>
                 )
                }
                          {
                  showSpreadAllColumns && (
                    <Column
                      field="Abr"
                      columnKey="Abr"
                      header={t('indIncCompensation:Abr')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                      editor={(props) => NumberEditor("Abr", props, 2)}
                    >
                    </Column>
                 )
                }
                          {
                  showSpreadAllColumns && (
                    <Column
                      field="May"
                      columnKey="May"
                      header={t('indIncCompensation:May')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("May", rowData)}
                      editor={(props) => NumberEditor("May", props, 2)}
                    >
                    </Column>
                 )
                }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Jun"
                    columnKey="Jun"
                    header={t('indIncCompensation:Jun')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                    editor={(props) => NumberEditor("Jun", props, 2)}
                  >
                  </Column>
               )
              }
                          {
                showSpreadAllColumns && (
                <Column
                  field="Jul"
                  columnKey="Jul"
                  header={t('indIncCompensation:Jul')}
                  headerStyle={{ width: '90px' }}
                  className="table-header-background-ligth-green"
                  body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                  editor={(props) => NumberEditor("Jul", props, 2)}
                >
                </Column>
                )
                }
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Ago"
                    columnKey="Ago"
                    header={t('indIncCompensation:Ago')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                    editor={(props) => NumberEditor("Ago", props, 2)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Sep"
                    columnKey="Sep"
                    header={t('indIncCompensation:Sep')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                    editor={(props) => NumberEditor("Sep", props, 2)}
                  >
                  </Column>
                )
              }
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Oct"
                    columnKey="Oct"
                    header={t('indIncCompensation:Oct')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                    editor={(props) => NumberEditor("Oct", props, 2)}
                  >
                  </Column>
                )
              }
                          {
                  showSpreadAllColumns && (
                  <Column
                    field="Nov"
                    columnKey="Nov"
                    header={t('indIncCompensation:Nov')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                    editor={(props) => NumberEditor("Nov", props, 2)}
                  >
                  </Column> 
                )
                }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationStrategicTons)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerStrategicTons}
                        >
                          <Column
                            field="NameStrategicTons"
                            columnKey="NameStrategicTons"
                            header={t('indIncCompensation:StrategicTons')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Dic"
                    columnKey="Dic"
                    header={t('indIncCompensation:Dic')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                    editor={(props) => NumberEditor("Dic", props, 3)}
                  >
                  </Column>
                  )
                }
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Ene"
                    columnKey="Ene"
                    header={t('indIncCompensation:Ene')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                    editor={(props) => NumberEditor("Ene", props, 3)}
                  >
                  </Column>
                )
                }
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Feb"
                    columnKey="Feb"
                    header={t('indIncCompensation:Feb')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                    editor={(props) => NumberEditor("Feb", props, 3)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Mar"
                    columnKey="Mar"
                    header={t('indIncCompensation:Mar')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                    editor={(props) => NumberEditor("Mar", props, 3)}
                  >
                  </Column>
                 )
                }
                          {
                  showStrategicTonsAllColumns && (
                    <Column
                      field="Abr"
                      columnKey="Abr"
                      header={t('indIncCompensation:Abr')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                      editor={(props) => NumberEditor("Abr", props, 3)}
                    >
                    </Column>
                 )
                }
                          {
                  showStrategicTonsAllColumns && (
                    <Column
                      field="May"
                      columnKey="May"
                      header={t('indIncCompensation:May')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("May", rowData)}
                      editor={(props) => NumberEditor("May", props, 3)}
                    >
                    </Column>
                 )
                }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Jun"
                    columnKey="Jun"
                    header={t('indIncCompensation:Jun')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                    editor={(props) => NumberEditor("Jun", props, 3)}
                  >
                  </Column>
               )
              }
                          {
                showStrategicTonsAllColumns && (
                <Column
                  field="Jul"
                  columnKey="Jul"
                  header={t('indIncCompensation:Jul')}
                  headerStyle={{ width: '90px' }}
                  className="table-header-background-ligth-green"
                  body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                  editor={(props) => NumberEditor("Jul", props, 3)}
                >
                </Column>
                )
                }
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Ago"
                    columnKey="Ago"
                    header={t('indIncCompensation:Ago')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                    editor={(props) => NumberEditor("Ago", props, 3)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Sep"
                    columnKey="Sep"
                    header={t('indIncCompensation:Sep')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                    editor={(props) => NumberEditor("Sep", props, 3)}
                  >
                  </Column>
                )
              }
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Oct"
                    columnKey="Oct"
                    header={t('indIncCompensation:Oct')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                    editor={(props) => NumberEditor("Oct", props, 3)}
                  >
                  </Column>
                )
              }
                          {
                  showStrategicTonsAllColumns && (
                  <Column
                    field="Nov"
                    columnKey="Nov"
                    header={t('indIncCompensation:Nov')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                    editor={(props) => NumberEditor("Nov", props, 3)}
                  >
                  </Column> 
                )
                }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationTeamProfitServe)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                        >
                          <Column
                            field="NameTeamProfitToServe"
                            columnKey="NameTeamProfitToServe"
                            header={t('indIncCompensation:TeamProfit')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                            editor={(props) => NumberEditor("Total", props, 4)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={InsentiveCompensationTotalPay}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                        >
                          <Column
                            field="NameTotalPay"
                            columnKey="NameTotalPay"
                            header={t('indIncCompensation:TotalPay')}
                            headerStyle={{ width: '160px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Dic"
                    columnKey="Dic"
                    header={t('indIncCompensation:Dic')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                  >
                  </Column>
                  )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Ene"
                    columnKey="Ene"
                    header={t('indIncCompensation:Ene')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                  >
                  </Column>
                )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Feb"
                    columnKey="Feb"
                    header={t('indIncCompensation:Feb')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Mar"
                    columnKey="Mar"
                    header={t('indIncCompensation:Mar')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                  >
                  </Column>
                 )
                }
                          {
                  showTotalPayAllColumns && (
                    <Column
                      field="Abr"
                      columnKey="Abr"
                      header={t('indIncCompensation:Abr')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                    >
                    </Column>
                 )
                }
                          {
                  showTotalPayAllColumns && (
                    <Column
                      field="May"
                      columnKey="May"
                      header={t('indIncCompensation:May')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("May", rowData)}
                    >
                    </Column>
                 )
                }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Jun"
                    columnKey="Jun"
                    header={t('indIncCompensation:Jun')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                  >
                  </Column>
               )
              }
                          {
                showTotalPayAllColumns && (
                <Column
                  field="Jul"
                  columnKey="Jul"
                  header={t('indIncCompensation:Jul')}
                  headerStyle={{ width: '90px' }}
                  className="table-header-background-ligth-green"
                  body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                >
                </Column>
                )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Ago"
                    columnKey="Ago"
                    header={t('indIncCompensation:Ago')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Sep"
                    columnKey="Sep"
                    header={t('indIncCompensation:Sep')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                  >
                  </Column>
                )
              }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Oct"
                    columnKey="Oct"
                    header={t('indIncCompensation:Oct')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                  >
                  </Column>
                )
              }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Nov"
                    columnKey="Nov"
                    header={t('indIncCompensation:Nov')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                  >
                  </Column> 
                )
                }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                          <Column
                            field="OfTarget"
                            columnKey="OfTarget"
                            header={t('indIncCompensation:OfTarget')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={percentageOfTarget}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <small>{t('indIncCompensation:DisclaimerInciativePrg')}</small>
                      </Col>
                    </Row>
                  </>
                ):(
                  <>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationStrategicTonsV2)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerStrategicTonsV2}
                        >
                          <Column
                            field="NameColumn"
                            columnKey="NameTons"
                            header={t('indIncCompensation:StrategicTonsV2')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                              showStrategicTonsV2AllColumns && (
                              <Column
                                field="Dic"
                                columnKey="Dic"
                                header={t('indIncCompensation:Dic')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                                editor={(props) => NumberEditor("Dic", props, 1)}
                              >
                              </Column>
                              )
                            }
                          {
                              showStrategicTonsV2AllColumns && (
                              <Column
                                field="Ene"
                                columnKey="Ene"
                                header={t('indIncCompensation:Ene')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                                editor={(props) => NumberEditor("Ene", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                                showStrategicTonsV2AllColumns && (
                                <Column
                                  field="Feb"
                                  columnKey="Feb"
                                  header={t('indIncCompensation:Feb')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                                  editor={(props) => NumberEditor("Feb", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                              showStrategicTonsV2AllColumns && (
                              <Column
                                field="Mar"
                                columnKey="Mar"
                                header={t('indIncCompensation:Mar')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                                editor={(props) => NumberEditor("Mar", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                              showStrategicTonsV2AllColumns && (
                                <Column
                                  field="Abr"
                                  columnKey="Abr"
                                  header={t('indIncCompensation:Abr')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                                  editor={(props) => NumberEditor("Abr", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                              showStrategicTonsV2AllColumns && (
                                <Column
                                  field="May"
                                  columnKey="May"
                                  header={t('indIncCompensation:May')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("May", rowData)}
                                  editor={(props) => NumberEditor("May", props, 1)}
                                >
                                </Column>
                            )
                            }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                                showStrategicTonsV2AllColumns && (
                                <Column
                                  field="Jun"
                                  columnKey="Jun"
                                  header={t('indIncCompensation:Jun')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                                  editor={(props) => NumberEditor("Jun", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                            showStrategicTonsV2AllColumns && (
                            <Column
                              field="Jul"
                              columnKey="Jul"
                              header={t('indIncCompensation:Jul')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                              editor={(props) => NumberEditor("Jul", props, 1)}
                            >
                            </Column>
                            )
                            }
                          {
                                showStrategicTonsV2AllColumns && (
                                <Column
                                  field="Ago"
                                  columnKey="Ago"
                                  header={t('indIncCompensation:Ago')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                                  editor={(props) => NumberEditor("Ago", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                      showStrategicTonsV2AllColumns && (
                      <Column
                        field="Sep"
                        columnKey="Sep"
                        header={t('indIncCompensation:Sep')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                        editor={(props) => NumberEditor("Sep", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showStrategicTonsV2AllColumns && (
                      <Column
                        field="Oct"
                        columnKey="Oct"
                        header={t('indIncCompensation:Oct')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                        editor={(props) => NumberEditor("Oct", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showStrategicTonsV2AllColumns && (
                      <Column
                        field="Nov"
                        columnKey="Nov"
                        header={t('indIncCompensation:Nov')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                        editor={(props) => NumberEditor("Nov", props, 1)}
                      >
                      </Column> 
                    )
                    }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationRevenueExworksV2)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerRevenueExworksV2}
                        >
                          <Column
                            field="NameColumn"
                            columnKey="NameTons"
                            header={t('indIncCompensation:RevenueExworks')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                              showRevenueExworksV2AllColumns && (
                              <Column
                                field="Dic"
                                columnKey="Dic"
                                header={t('indIncCompensation:Dic')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                                editor={(props) => NumberEditor("Dic", props, 1)}
                              >
                              </Column>
                              )
                            }
                          {
                              showRevenueExworksV2AllColumns && (
                              <Column
                                field="Ene"
                                columnKey="Ene"
                                header={t('indIncCompensation:Ene')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                                editor={(props) => NumberEditor("Ene", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                                showRevenueExworksV2AllColumns && (
                                <Column
                                  field="Feb"
                                  columnKey="Feb"
                                  header={t('indIncCompensation:Feb')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                                  editor={(props) => NumberEditor("Feb", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                              showRevenueExworksV2AllColumns && (
                              <Column
                                field="Mar"
                                columnKey="Mar"
                                header={t('indIncCompensation:Mar')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                                editor={(props) => NumberEditor("Mar", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                              showRevenueExworksV2AllColumns && (
                                <Column
                                  field="Abr"
                                  columnKey="Abr"
                                  header={t('indIncCompensation:Abr')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                                  editor={(props) => NumberEditor("Abr", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                              showRevenueExworksV2AllColumns && (
                                <Column
                                  field="May"
                                  columnKey="May"
                                  header={t('indIncCompensation:May')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("May", rowData)}
                                  editor={(props) => NumberEditor("May", props, 1)}
                                >
                                </Column>
                            )
                            }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                                showRevenueExworksV2AllColumns && (
                                <Column
                                  field="Jun"
                                  columnKey="Jun"
                                  header={t('indIncCompensation:Jun')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                                  editor={(props) => NumberEditor("Jun", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                            showRevenueExworksV2AllColumns && (
                            <Column
                              field="Jul"
                              columnKey="Jul"
                              header={t('indIncCompensation:Jul')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                              editor={(props) => NumberEditor("Jul", props, 1)}
                            >
                            </Column>
                            )
                            }
                          {
                                showRevenueExworksV2AllColumns && (
                                <Column
                                  field="Ago"
                                  columnKey="Ago"
                                  header={t('indIncCompensation:Ago')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                                  editor={(props) => NumberEditor("Ago", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                      showRevenueExworksV2AllColumns && (
                      <Column
                        field="Sep"
                        columnKey="Sep"
                        header={t('indIncCompensation:Sep')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                        editor={(props) => NumberEditor("Sep", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showRevenueExworksV2AllColumns && (
                      <Column
                        field="Oct"
                        columnKey="Oct"
                        header={t('indIncCompensation:Oct')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                        editor={(props) => NumberEditor("Oct", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showRevenueExworksV2AllColumns && (
                      <Column
                        field="Nov"
                        columnKey="Nov"
                        header={t('indIncCompensation:Nov')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                        editor={(props) => NumberEditor("Nov", props, 1)}
                      >
                      </Column> 
                    )
                    }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationCustomerRetentionV2)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                          header={headerCustomerRetentionV2}
                        >
                          <Column
                            field="NameColumn"
                            columnKey="NameTons"
                            header={t('indIncCompensation:CustomerRetention')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                              showCustomerRetentionV2AllColumns && (
                              <Column
                                field="Dic"
                                columnKey="Dic"
                                header={t('indIncCompensation:Dic')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                                editor={(props) => NumberEditor("Dic", props, 1)}
                              >
                              </Column>
                              )
                            }
                          {
                              showCustomerRetentionV2AllColumns && (
                              <Column
                                field="Ene"
                                columnKey="Ene"
                                header={t('indIncCompensation:Ene')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                                editor={(props) => NumberEditor("Ene", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                                showCustomerRetentionV2AllColumns && (
                                <Column
                                  field="Feb"
                                  columnKey="Feb"
                                  header={t('indIncCompensation:Feb')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                                  editor={(props) => NumberEditor("Feb", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                              showCustomerRetentionV2AllColumns && (
                              <Column
                                field="Mar"
                                columnKey="Mar"
                                header={t('indIncCompensation:Mar')}
                                headerStyle={{ width: '90px' }}
                                className="table-header-background-ligth-green"
                                body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                                editor={(props) => NumberEditor("Mar", props, 1)}
                              >
                              </Column>
                            )
                            }
                          {
                              showCustomerRetentionV2AllColumns && (
                                <Column
                                  field="Abr"
                                  columnKey="Abr"
                                  header={t('indIncCompensation:Abr')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                                  editor={(props) => NumberEditor("Abr", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                              showCustomerRetentionV2AllColumns && (
                                <Column
                                  field="May"
                                  columnKey="May"
                                  header={t('indIncCompensation:May')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("May", rowData)}
                                  editor={(props) => NumberEditor("May", props, 1)}
                                >
                                </Column>
                            )
                            }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                                showCustomerRetentionV2AllColumns && (
                                <Column
                                  field="Jun"
                                  columnKey="Jun"
                                  header={t('indIncCompensation:Jun')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                                  editor={(props) => NumberEditor("Jun", props, 1)}
                                >
                                </Column>
                            )
                            }
                          {
                            showCustomerRetentionV2AllColumns && (
                            <Column
                              field="Jul"
                              columnKey="Jul"
                              header={t('indIncCompensation:Jul')}
                              headerStyle={{ width: '90px' }}
                              className="table-header-background-ligth-green"
                              body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                              editor={(props) => NumberEditor("Jul", props, 1)}
                            >
                            </Column>
                            )
                            }
                          {
                                showCustomerRetentionV2AllColumns && (
                                <Column
                                  field="Ago"
                                  columnKey="Ago"
                                  header={t('indIncCompensation:Ago')}
                                  headerStyle={{ width: '90px' }}
                                  className="table-header-background-ligth-green"
                                  body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                                  editor={(props) => NumberEditor("Ago", props, 1)}
                                >
                                </Column>
                              )
                            }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                      showCustomerRetentionV2AllColumns && (
                      <Column
                        field="Sep"
                        columnKey="Sep"
                        header={t('indIncCompensation:Sep')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                        editor={(props) => NumberEditor("Sep", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showCustomerRetentionV2AllColumns && (
                      <Column
                        field="Oct"
                        columnKey="Oct"
                        header={t('indIncCompensation:Oct')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                        editor={(props) => NumberEditor("Oct", props, 1)}
                      >
                      </Column>
                    )
                  }
                          {
                      showCustomerRetentionV2AllColumns && (
                      <Column
                        field="Nov"
                        columnKey="Nov"
                        header={t('indIncCompensation:Nov')}
                        headerStyle={{ width: '90px' }}
                        className="table-header-background-ligth-green"
                        body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                        editor={(props) => NumberEditor("Nov", props, 1)}
                      >
                      </Column> 
                    )
                    }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={6} md={6} sm={12} xs={12}>
                        <FieldDataTable
                          value={showActiveRows(InsentiveCompensationTeamProfitServe)}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                        >
                          <Column
                            field="NameTeamProfitToServe"
                            columnKey="NameTeamProfitToServe"
                            header={t('indIncCompensation:TeamProfit')}
                            headerStyle={{ width: '220px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                            editor={(props) => NumberEditor("Total", props, 4)}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row className="pt-5">
                      <Col lg={12} md={12} sm={12} xs={12}>
                        <FieldDataTable
                          value={InsentiveCompensationTotalPay}
                          scrollable
                          className="editable-cells-table"
                          editMode="cell"
                          selectionMode="single"
                          paginator={false}
                        >
                          <Column
                            field="NameTotalPay"
                            columnKey="NameTotalPay"
                            header={t('indIncCompensation:TotalPay')}
                            headerStyle={{ width: '160px' }}
                            className="table-header-background-ligth-green"
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Dic"
                    columnKey="Dic"
                    header={t('indIncCompensation:Dic')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Dic", rowData)}
                  >
                  </Column>
                  )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Ene"
                    columnKey="Ene"
                    header={t('indIncCompensation:Ene')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ene", rowData)}
                  >
                  </Column>
                )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Feb"
                    columnKey="Feb"
                    header={t('indIncCompensation:Feb')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Feb", rowData)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q1"
                            columnKey="Q1"
                            header={t('indIncCompensation:Q1')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q1", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Mar"
                    columnKey="Mar"
                    header={t('indIncCompensation:Mar')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Mar", rowData)}
                  >
                  </Column>
                 )
                }
                          {
                  showTotalPayAllColumns && (
                    <Column
                      field="Abr"
                      columnKey="Abr"
                      header={t('indIncCompensation:Abr')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("Abr", rowData)}
                    >
                    </Column>
                 )
                }
                          {
                  showTotalPayAllColumns && (
                    <Column
                      field="May"
                      columnKey="May"
                      header={t('indIncCompensation:May')}
                      headerStyle={{ width: '90px' }}
                      className="table-header-background-ligth-green"
                      body={(rowData, props) => RederFormatColumn("May", rowData)}
                    >
                    </Column>
                 )
                }
                          <Column
                            field="Q2"
                            columnKey="Q2"
                            header={t('indIncCompensation:Q2')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q2", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Jun"
                    columnKey="Jun"
                    header={t('indIncCompensation:Jun')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Jun", rowData)}
                  >
                  </Column>
               )
              }
                          {
                showTotalPayAllColumns && (
                <Column
                  field="Jul"
                  columnKey="Jul"
                  header={t('indIncCompensation:Jul')}
                  headerStyle={{ width: '90px' }}
                  className="table-header-background-ligth-green"
                  body={(rowData, props) => RederFormatColumn("Jul", rowData)}
                >
                </Column>
                )
                }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Ago"
                    columnKey="Ago"
                    header={t('indIncCompensation:Ago')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Ago", rowData)}
                  >
                  </Column>
                )
              }
                          <Column
                            field="Q3"
                            columnKey="Q3"
                            header={t('indIncCompensation:Q3')}
                            headerStyle={{ width: '90px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q3", rowData)}
                          >
                          </Column>
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Sep"
                    columnKey="Sep"
                    header={t('indIncCompensation:Sep')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Sep", rowData)}
                  >
                  </Column>
                )
              }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Oct"
                    columnKey="Oct"
                    header={t('indIncCompensation:Oct')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Oct", rowData)}
                  >
                  </Column>
                )
              }
                          {
                  showTotalPayAllColumns && (
                  <Column
                    field="Nov"
                    columnKey="Nov"
                    header={t('indIncCompensation:Nov')}
                    headerStyle={{ width: '90px' }}
                    className="table-header-background-ligth-green"
                    body={(rowData, props) => RederFormatColumn("Nov", rowData)}
                  >
                  </Column> 
                )
                }
                          <Column
                            field="Q4"
                            columnKey="Q4"
                            header={t('indIncCompensation:Q4')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Q4", rowData)}
                          >
                          </Column>
                          <Column
                            field="Total"
                            columnKey="Total"
                            header={t('indIncCompensation:Total')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={(rowData, props) => RederFormatColumn("Total", rowData)}
                          >
                          </Column>
                          <Column
                            field="OfTarget"
                            columnKey="OfTarget"
                            header={t('indIncCompensation:OfTarget')}
                            headerStyle={{ width: '85px' }}
                            className="table-header-background-dark-green"
                            body={percentageOfTarget}
                          >
                          </Column>
                        </FieldDataTable>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <small>{t('indIncCompensation:DisclaimerInciativePrg')}</small>
                      </Col>
                    </Row>
                  </>  
                )
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      {EsDetailInvoice ? (
        <>
          <IndividualIncentiveCompensationDetailByInvoice
            ClaAnio={props.ClaAnio}
            ClaAgenteVentas={props.ClaAgenteVentas}
            ClaMes={props.ClaMes}
            IsVisible={EsDetailInvoice}
            onClickClose={onHideDetailInvoice}
          >
          </IndividualIncentiveCompensationDetailByInvoice>
        </>
        ) : (
          <></>
        )} 

      {
        EsViewMonthlyGoals ?
        (
          <>
            <Dialog
              visible={EsViewMonthlyGoals}
              style={{ width: '90vw' }}
              modal 
              onHide={() => setEsViewMonthlyGoals(false)}
              header=""
              className="dialog-transparent"
              contentClassName="dialog-content-transparent"
              closable={false}
            >  
              <Row>
                <Col>
                  <IncentiveProgramsGoalsSalesRep 
                    ClaAnio={props.ClaAnio} 
                    IsReadOnly={true} 
                    ClaAgenteVentas={props.ClaAgenteVentas}
                    showCloseButton={true}
                    onCloseClick={() => setEsViewMonthlyGoals(false)}
                  >
                  </IncentiveProgramsGoalsSalesRep>
                </Col>
              </Row>
            </Dialog>
          </>
        )
        :
        (<></>)
      }
    </>
  );
};
export default IndividualIncetiveCompensation;
