/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter, Label } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, getSessionItem, showSweetAlert } from '../../../utils/utils';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import SweatAlertCustomIcon from '../../../components/SweatAlert/SweatAlertCustomIcon';
import moment from 'moment';
import Highcharts from 'highcharts';
import swal from '@sweetalert/with-react';
import PageContent from 'layouts/PageContent';
import { Toolbar } from 'primereact/toolbar';
import { ProgressBar } from 'primereact/progressbar';
import FieldDropdown from 'components/Controls/FieldDropdown';
import FieldDropdownMultiSelect from '../../../components/Controls/FieldDropDownMultiSelect/FieldDropdownMultiSelect.jsx';
import FieldText from 'components/Controls/FieldText.jsx';
import FieldNumber from 'components/Controls/FieldNumber.jsx';
import FieldCheckbox from 'components/Controls/FieldCheckbox.jsx';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import { Dialog } from 'primereact/dialog';
import Select, { InputActionMeta } from 'react-select';
import { Panel } from 'primereact/panel';
import IncentiveProgramSetUpEdit from './IncentiveProgramSetUpEdit.jsx';
import IncetiveProgramSetUpMonthStatus from './IncetiveProgramSetUpMonthStatus.jsx';

const IncetiveProgramSetUpFilters = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']); 
  const [errors, setErrors] = useState({});
  const [EsVerCompensacionAg, setEsVerCompensacionAg] = useState(false);
  const [EsVerMonthStatus, setEsVerMonthStatus] = useState(false);
  const [ShowOnlyInactives, setShowOnlyInactives] = useState(false);
  const [OpcionesAnio, setOpcionesAnio] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setErrors({});
    ObtenerAnio();
  }, []);


  const onApplyFilters = async () => {     
    if (props.onSearch) {
        const filtersData = {}; 
        filtersData.AddNewRow = 0;    
        filtersData.ShowOnlyInactives = ShowOnlyInactives; 
        filtersData.ClaAnio = ClaAnio; 
        props.onSearch(filtersData);      
    }
  };

  const ObtenerAnio = () => {
      const GetAnioCmb = `${config.UrlApiProject}ScoreCard/GetAnioCmb`;
      const paramsToService = {};
      callApi(GetAnioCmb, 'GET', paramsToService, (response) => {
        setOpcionesAnio(response.data.Anio);  
        setClaAnio(currentYear);
      });
    }; 

  const onClickNew = () => {  
    setEsVerCompensacionAg(true);
    // if (props.onNew) {
    //   const filtersData = {};
    //   filtersData.SalesPerson = null;  
    //   filtersData.ClaAgente = null
    //   filtersData.ClaUsuario = null;
    //   filtersData.ClaTipoAgente = null;
    //   filtersData.NomTipoAgente = null;
    //   filtersData.PctIncentivo = null;
    //   filtersData.FechaInicio = null;
    //   filtersData.Comentario = null;
    //   filtersData.FechaBaja = null;
    //   filtersData.ComentarioBaja = null;   
    //   filtersData.AddNewRow = 1;   
    //   props.onNew(filtersData);
    // }
  } 
  const closeModalCompesacionAg = () => {
    setEsVerCompensacionAg(false);
  };

  const filterHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down text-white' : 'pi pi-chevron-up text-white';
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className="card-header">
        <div className="p-toolbar p-component">
          <div className="p-toolbar-group-left">
            <div className="card-title">
              <FieldButton className={options.togglerClassName} onClick={options.onTogglerClick}>
                <span className={toggleIcon}></span> 
              </FieldButton>
              <span className={titleClassName}> 
                {t('common:Filters')}
              </span>
            </div>  
          </div>
         
          <div className="p-toolbar-group-right">
            <FieldButton label={t('salesPerformanceSales:Search')} icon="pi pi-search" className="p-button p-highlight" onClick={onApplyFilters}>              
            </FieldButton> 
            &nbsp;
            <FieldButton label={t('salesPerformanceSales:New')} icon="pi pi-plus" className="light-green no-border p-mr-2" onClick={onClickNew}>              
            </FieldButton> 
            &nbsp;
            <FieldButton label={t('myactivities:MonthStatus')} className="secondary no-border" onClick={onChangeMonthStatus}>              
            </FieldButton>
            {/* <FieldButton label={t('myactivities:Export')} icon="fa fa-file-excel" className="secondary no-border" onClick={onExportExcelMyProject}>              
            </FieldButton>             */}
          </div>
        </div> 
      </div>
    )
}

const onChangeMonthStatus = () => {
  setEsVerMonthStatus(true);    
};

const onHideMonthStatus = () => {
  setEsVerMonthStatus(false);    
};

const onChangeOnlyInactives = (props, e) => {
  setShowOnlyInactives(e.checked);
};

const onClaAnioChange = (props, e) => {
  setClaAnio(e.value);
};


  return (
    <>
      <Panel
        header={t('common:Filters')}
        toggleable 
        headerTemplate={filterHeaderTemplate}
      >  
        <Row>
          <Col>
            <div className="p-fluid d-flex align-items-center p-3">
              <div className="d-flex align-items-center">
                <FieldDropdown
                  name="ClaAnio"
                  value={ClaAnio}
                  options={OpcionesAnio}
                  optionValue="ClaAnio"
                  optionLabel="NomAnio"
                  label={t('salesPerformanceSales:Anio')}
                  onChange={(e) => onClaAnioChange(props, e)}
                  showClear={false}
                />
              </div> 
            </div>
          </Col> 
          <Col className='text-right'> 
            <div className='pr-2'>
              <FieldCheckbox
                name={t('salesPerformanceSales:OnlyInactives')}
                inputId={ShowOnlyInactives}
                checked={ShowOnlyInactives}
                onChange={(e) => onChangeOnlyInactives(props, e)}
              />
              <Label className="ml-2">{t('salesPerformanceSales:OnlyInactives')}</Label>
            </div>
          </Col>
        </Row>  
      </Panel>
      {
        EsVerCompensacionAg ?
        (  
          <IncentiveProgramSetUpEdit          
            propsClaAgenteVentas={null} 
            propsClaAnio={null}        
            propsClaUsuario={null}          
            onCloseClick={closeModalCompesacionAg}
            showCloseButton={true}
            EsVerCompensacionAg={EsVerCompensacionAg}
            EsNew={true}
          >
          </IncentiveProgramSetUpEdit>
        )
        :
        (<></>)
      }    
      {EsVerMonthStatus ? (
        <>
          <IncetiveProgramSetUpMonthStatus              
            IsVisible={EsVerMonthStatus}
            onClickClose={onHideMonthStatus}
          >
          </IncetiveProgramSetUpMonthStatus>
        </>
        ) : (
          <></>
        )}      
    </>
  );
};
export default IncetiveProgramSetUpFilters;
