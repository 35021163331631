/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { Dialog } from 'primereact/dialog';
import PropType from 'prop-types';
import { Toolbar } from 'primereact/toolbar';
import FieldAutoComplete from 'components/Controls/FieldAutoComplete.jsx';

const IncentiveProgramSetUpEdit = ({
  propsClaAgenteVentas,
  propsClaAnio,
  propsClaUsuario,
  showCloseButton,
  onCloseClick,
  EsVerCompensacionAg,
  EsNew,
}) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['salesPerformanceSales', 'common']);
  const [PlanIncentivoList, setPlanIncentivoList] = useState([]);
  const [PlanIncentivoByIdList, setPlanIncentivoByIdList] = useState([]);
  const [OpcionesClaIncentivo, setOpcionesClaIncentivo] = useState([]);
  const [OpcionesTipoAgente, setOpcionesTipoAgente] = useState([]);
  const [OpcionesTipoPago, setOpcionesTipoPago] = useState([]);
  const [OpcionesClaAgenteAg, setOpcionesClaAgenteAg] = useState([]);
  const [ClaAgente, setClaAgente] = useState(null);
  const [ClaTipoAgente, setClaTipoAgente] = useState(null);
  const [PctIncentivo, setPctIncentivo] = useState(null);
  const [AnnualSPITargetIncentive, setAnnualSPITargetIncentive] = useState(null);
  const [FechaInicio, setFechaInicio] = useState(null);
  const [FechaBaja, setFechaBaja] = useState(null);
  const [Comentario, setComentario] = useState(null);
  const [ComentarioBaja, setComentarioBaja] = useState(null);
  const [SalesPerson, setSalesPerson] = useState(null);
  const [NomTipoAgente, setNomTipoAgente] = useState(null);
  const [ClaAgenteVentas, setClaAgenteVentas] = useState(null);
  const [ClaColaborador, setClaColaborador] = useState(null);
  const [filteredResponsible, setfilteredResponsible] = useState([]);
  const [ClaUsuario, setClaUsuario] = useState(null);  
  const [errors, setErrors] = useState({});
  const [CompensationAgAnualTargetDet, setCompensationAgAnualTargetDet] = useState([]);
  const [OpcionesAnio, setOpcionesAnio] = useState([]);
  const [ClaAnio, setClaAnio] = useState(null);

  useEffect(() => {
    ObtenerAnio();
    GetAgenteCmb();    
    GetIncentivoCmb();
    GetTipoAgenteCmb();
    GetTipoPagoCmb();    
    GetPlanIncentivo(propsClaUsuario,propsClaAnio);
    GetCompensationAgById(propsClaAgenteVentas,propsClaAnio);
    // if (EsVerCompensacionAg) {
    //   document.body.classList.add('bodynotscroll');
    // }
  }, [propsClaAgenteVentas, propsClaAnio, propsClaUsuario, showCloseButton, onCloseClick, EsVerCompensacionAg, EsNew]);

   const ObtenerAnio = () => {
        const GetAnioCmb = `${config.UrlApiProject}ScoreCard/GetAnioCmb`;
        const paramsToService = {};
        callApi(GetAnioCmb, 'GET', paramsToService, (response) => {
          setOpcionesAnio(response.data.Anio);  
          if(EsNew){
            setClaAnio(new Date().getFullYear());
          }
        });
      }; 
  

  const GetPlanIncentivo = (pClaUsuario,pClaAnio) => {
    const getPlanIncentivo = `${config.UrlApiProject}SalesPerformanceSales/GetPlanIncentivo`;
    const paramsToService = {
      ClaUsuario: pClaUsuario,
      ClaAnio: pClaAnio
    };

    callApi(getPlanIncentivo, 'POST', paramsToService, (response) => {
      setPlanIncentivoList(response.data.PlanIncentivo);
    });
  };
  
  const GetCompensationAgById = (pClaUsuario,pClaAnio) => {
    const getCompensationAgById = `${config.UrlApiProject}SalesPerformanceSales/GetCompensationAgById`;
    const paramsToService = {
      ClaAgenteVentas: pClaUsuario, 
      ClaAnio: pClaAnio
    };

    callApi(getCompensationAgById, 'POST', paramsToService, (response) => {
      const planIncentivoById =
        response.data.CompensationAgById.length > 0
          ? response.data.CompensationAgById[0]
          : undefined;
      if (planIncentivoById !== undefined) {
        setClaAnio(planIncentivoById.Anio);
        setClaAgente(planIncentivoById.ClaAgenteVentas);
        setClaAgenteVentas(planIncentivoById.ClaAgenteVentas);
        setClaTipoAgente(planIncentivoById.ClaTipoAgente);
        setComentario(planIncentivoById.Comentario);
        setComentarioBaja(planIncentivoById.ComentarioBaja);
        setFechaBaja(planIncentivoById.FechaBaja);
        setFechaInicio(planIncentivoById.FechaInicio);
        setNomTipoAgente(planIncentivoById.NomTipoAgente);
        setPctIncentivo(planIncentivoById.PctIncentivo);
        setAnnualSPITargetIncentive(planIncentivoById.AnnualSPITargetIncentive);
        setSalesPerson(planIncentivoById.SalesPerson);
        setClaUsuario(planIncentivoById.ClaUsuario);
        setClaColaborador({ClaColaborador:planIncentivoById.ClaUsuario, NomColaborador:planIncentivoById.NomColaborador});
      }
      setCompensationAgAnualTargetDet(response.data.CompensationAgAnualTargetDet);
    });
  };

  const GetAgenteById_Sel = (pclaAgente) => {
    const getAgenteById_Sel = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteById_Sel`;
    const paramsToService = {
      ClaAgenteVentas: pclaAgente,
    };

    callApi(getAgenteById_Sel, 'POST', paramsToService, (response) => {
      const planIncentivoById =
        response.data.AgenteById.length > 0 ? response.data.AgenteById[0] : undefined;

      if (planIncentivoById !== undefined) {
        const usuario = {
          ClaColaborador: planIncentivoById.ClaUsuario,
          NomColaborador: planIncentivoById.NomUsuario,
        };
        setClaColaborador(usuario);
      }
    });
  };

  const GetIncentivoCmb = () => {
    const getIncentivoCmb = `${config.UrlApiProject}SalesPerformanceSales/GetIncentivoCmb`;
    const paramsToService = {};
    callApi(getIncentivoCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaIncentivo(response.data.Incentivo);
    });
  };

  const GetTipoAgenteCmb = () => {
    const getTipoAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetTipoAgenteCmb`;
    const paramsToService = {};
    callApi(getTipoAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesTipoAgente(response.data.TipoAgente);
    });
  };

  const GetTipoPagoCmb = () => {
    const getTipoPagoCmb = `${config.UrlApiProject}SalesPerformanceSales/GetTipoPagoCmb`;
    const paramsToService = {};
    callApi(getTipoPagoCmb, 'GET', paramsToService, (response) => {
      setOpcionesTipoPago(response.data.TipoPago);
    });
  };

  const GetAgenteCmb = () => {
    const getAgenteCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteCmb`;
    const paramsToService = {};
    callApi(getAgenteCmb, 'GET', paramsToService, (response) => {
      setOpcionesClaAgenteAg(response.data.Agente);
    });
  };

  const ClaIncentivoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaIncentivo"
          value={props.rowData.ClaIncentivo}
          options={OpcionesClaIncentivo}
          optionValue="ClaIncentivo"
          optionLabel="NomIncentivo"
          onChange={(e) => onClaIncentivoChange(props, e)}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
          disabled={props.rowData.NewRow === 0}
        />
      </div>
    );
  };

  const onClaIncentivoChange = (props, e) => {
    const PlanIncentivoListCopy = [...PlanIncentivoList];
    const ClaIncentivo = OpcionesClaIncentivo.filter((item) => item.ClaIncentivo === e.value)[0];
    if (ClaIncentivo !== undefined) {
      // PlanIncentivoListCopy[props.rowData.RowIndex].ClaIncentivo = ClaIncentivo.ClaIncentivo;
      PlanIncentivoListCopy[props.rowData.RowIndex].NomIncentivo = ClaIncentivo.NomIncentivo;
    }else{
      PlanIncentivoListCopy[props.rowData.RowIndex].NomIncentivo = null;
    }
    PlanIncentivoListCopy[props.rowData.RowIndex].ClaIncentivo = e.value;
    setPlanIncentivoList(PlanIncentivoListCopy);
  };

  const ClaTipoPagoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldDropdown
          name="ClaTipoPago"
          value={props.rowData.ClaTipoPago}
          options={OpcionesTipoPago}
          optionValue="ClaTipoPago"
          optionLabel="NomTipoPago"
          onChange={(e) => onClaTipoPagoChange(props, e)}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const onClaTipoPagoChange = (props, e) => {
    const PlanIncentivoListCopy = [...PlanIncentivoList];
    const ClaTipoPago = OpcionesTipoPago.filter((item) => item.ClaTipoPago === e.value)[0];
    if (ClaTipoPago !== undefined) {
      PlanIncentivoListCopy[props.rowData.RowIndex].NomTipoPago = ClaTipoPago.NomTipoPago;
    }
    else { 
      PlanIncentivoListCopy[props.rowData.RowIndex].NomTipoPago = null;
    }
    PlanIncentivoListCopy[props.rowData.RowIndex].ClaTipoPago = e.value;
    setPlanIncentivoList(PlanIncentivoListCopy);
  };

  const renderNumericValue = (value) => {
    return (
      <>
        {value === null
          ? ''
          : value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
      </>
    );
  };

  const renderFecha = (value) => {
    return (
      <>
        {value === null
          ? ''
          : moment(value).format("MMMM Do YYYY, HH:mm") }
      </>
    );
  };

  const PctPonderadoEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctPonderado"
          value={props.rowData.PctPonderado}
          onChange={(e) => PctPonderadoChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          mode="decimal"
          locale="en-US"
          minFractionDigits={2}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const PctPonderadoChange = (props, value) => {
    const UpdPlanIncentivoListCopy = [...props.value];
    UpdPlanIncentivoListCopy[props.rowIndex][props.field] = value;

    setPlanIncentivoList(UpdPlanIncentivoListCopy);
  };

  const PctMinGoalEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctMinGoal"
          value={props.rowData.PctMinGoal}
          onChange={(e) => PctMinGoalChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          mode="decimal"
          locale="en-US"
          minFractionDigits={2}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const PctMinGoalChange = (props, value) => {
    const UpdPlanIncentivoListCopy = [...props.value];
    UpdPlanIncentivoListCopy[props.rowIndex][props.field] = value;

    setPlanIncentivoList(UpdPlanIncentivoListCopy);
  };

  const PctIncentivoMinEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctIncentivoMin"
          value={props.rowData.PctIncentivoMin}
          onChange={(e) => PctIncentivoMinChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          mode="decimal"
          locale="en-US"
          minFractionDigits={2}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const PctIncentivoMinChange = (props, value) => {
    const UpdPlanIncentivoListCopy = [...props.value];
    UpdPlanIncentivoListCopy[props.rowIndex][props.field] = value;

    setPlanIncentivoList(UpdPlanIncentivoListCopy);
  };

  const PctMaxGoalEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctMaxGoal"
          value={props.rowData.PctMaxGoal}
          onChange={(e) => PctMaxGoalChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          mode="decimal"
          locale="en-US"
          minFractionDigits={2}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const PctMaxGoalChange = (props, value) => {
    const UpdPlanIncentivoListCopy = [...props.value];
    UpdPlanIncentivoListCopy[props.rowIndex][props.field] = value;

    setPlanIncentivoList(UpdPlanIncentivoListCopy);
  };

  const PctIncentivoMaxEditor = (props) => {
    return (
      <div className="p-fluid">
        <FieldNumber
          name="PctIncentivoMax"
          value={props.rowData.PctIncentivoMax}
          onChange={(e) => PctIncentivoMaxChange(props, e.target.value)}
          min={-99999999.99}
          max={99999999.99}
          mode="decimal"
          locale="en-US"
          minFractionDigits={2}
          errors={errors[`PlanIncentivoList[${props.rowData.RowIndex}]`]}
        />
      </div>
    );
  };

  const PctIncentivoMaxChange = (props, value) => {
    const UpdPlanIncentivoListCopy = [...props.value];
    UpdPlanIncentivoListCopy[props.rowIndex][props.field] = value;

    setPlanIncentivoList(UpdPlanIncentivoListCopy);
  };

  const onClaAgenteChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.ClaAgente;
    delete newErrors.ClaColaborador;
    setErrors(newErrors);
    setClaAgente(value);
    GetAgenteById_Sel(value);
  };

  const onChangeClaUsuarioClaColaborador = (e) => {
    const newErrors = { ...errors };
    delete newErrors.ClaColaborador;
    setErrors(newErrors);
    setClaColaborador(e.value);
  };

  const filterResponsible = (event) => {
    let valor = '';
    if (ClaColaborador !== null && ClaColaborador !== '' && event.query === '') {
      valor = ClaColaborador.NomColaborador;
    } else {
      valor = event.query;
    }
    const paramsServiceOwners = {};
    // event.query
    const urlWebServiceOwners = `${config.UrlApiProject}ScoreCard/GetColaboradorProyectosCmb?descriptionFilter=${valor}&EsMostrarSoloUsuarioMSW=0`;

    // Get owner
    callApi(urlWebServiceOwners, 'GET', paramsServiceOwners, (response) => {
      setfilteredResponsible(response.data[0]);
    });
  };

  const onClaTipoAgenteChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.ClaTipoAgente;
    setErrors(newErrors);
    setClaTipoAgente(value);
  };

  const onPctIncentivoChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.PctIncentivo;
    setErrors(newErrors);
    setPctIncentivo(value);
  };

  const onAnnualSPITargetIncentiveChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.AnnualSPITargetIncentive;
    setErrors(newErrors);
    setAnnualSPITargetIncentive(value);
  };

  

  const onChangeFechaInicio = (value) => {
    const newErrors = { ...errors };
    delete newErrors.FechaInicio;
    setErrors(newErrors);
    setFechaInicio(value);
  };

  const ComentarioChange = (value) => {
    setComentario(value);
  };

  const onChangeFechaBaja = (value) => {
    const newErrors = { ...errors };
    delete newErrors.FechaBaja;
    setErrors(newErrors);
    setFechaBaja(value);
  };

  const ComentarioBajaChange = (value) => {
    const newErrors = { ...errors };
    delete newErrors.ComentarioBaja;
    setErrors(newErrors);
    setComentarioBaja(value);
  };

  const onClaAnioChange = (e) => { 
    const newErrors = { ...errors };
    delete newErrors.ClaAnio;
    setErrors(newErrors);
    setClaAnio(e.value);
  };
  

  const header = (
    <>
      <Row>
        <Col>{t('salesPerformanceSales:TitlePlanIncentivo')}</Col>
        <Col className="text-right">
          <FieldButton
            className="p-button p-button-icon-only p-button-info rounded-button"
            onClick={(e) => addRow()}
          >
            <i className="pi pi-plus"></i>
          </FieldButton>
        </Col>
      </Row>
    </>
  );

  const footer  = ( 
    <> 
      <Row>
        <Column></Column>    
        <Column></Column>          
        <Column className="text-center" footer={() => (<b> {t('common:Total')}</b>)}></Column> 
       
        <Column className="text-center" footer={() => (<b>{ (PlanIncentivoList.filter(opt => opt.BajaLogica === 0).reduce((a,v) =>  a += v.PctPonderado === null ? 0 : v.PctPonderado , 0 )).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }</b>)}></Column>             
      </Row>
    </>
  )

  const addRow = async () => {
    const newRow = {
      ClaUsuario,
      RowIndex: PlanIncentivoList.length,
      ClaIncentivo: null,
      NomIncentivo: null,
      PctPonderado: null,
      PctMinGoal: null,
      PctIncentivoMin: null,
      PctIncentivoMax: null,
      PctMaxGoal: null,
      ClaTipoPago: null,
      NomTipoPago: null,
      FechaBaja: null,
      ComentarioBaja: null,
      BajaLogica: 0,
      NewRow: 1
    };

    // setPlanIncentivoList([...PlanIncentivoList, newRow]);
    const updatedPlanIncentivoList = [...PlanIncentivoList];
    updatedPlanIncentivoList.push(newRow);

    const ClaIncentivo = OpcionesClaIncentivo.filter(
      (item) => item.ClaIncentivo === newRow.ClaIncentivo
    )[0];
    if (ClaIncentivo !== undefined) {
      updatedPlanIncentivoList[newRow.RowIndex].NomIncentivo = ClaIncentivo.NomIncentivo;
    }
    updatedPlanIncentivoList[newRow.RowIndex].ClaIncentivo = newRow.ClaIncentivo;

    setPlanIncentivoList(updatedPlanIncentivoList);
  };

  const onSaveClick = async () => {
    // const ListConfigurado = PlanIncentivoList.filter((opc) => opc.Configurado === 1);

    const value = {
      ClaAgente,
      ClaColaborador,
      ClaTipoAgente,
      PctIncentivo,
      FechaInicio,
      PlanIncentivoList,
      AnnualSPITargetIncentive,
      ClaAnio
    };

    const validator = new YupValidator(valitationSchemeSave);
    const esValido = await validator.validate(value);
    if (!esValido) {
      setErrors(validator.errors);
      showSweetAlert(t('common:Warning'), t('common:ReviewRequiredFields'), 'warning');
      return;
    }

    // const ListPlanIncentivo = PlanIncentivoList.filter(opt => opt.BajaLogica === 0);
    const ListPlanIncentivo = PlanIncentivoList;

    const paramsServicePlanIncentivo = {
      ClaAgenteVentas: ClaAgente,
      ClaUsuario   : ClaColaborador.ClaColaborador,
      ClaTipoAgente,
      PctIncentivo,
      FechaInicio,
      Comentario,
      FechaBaja,
      ComentarioBaja,
      AnnualSPITargetIncentive,
      NewRow : ClaUsuario === null ? 1: 0,
      CompensationDetList: ListPlanIncentivo,
      ClaAnio 
    };
    const urlWebServiceSavePlanIncentivo = `${config.UrlApiProject}SalesPerformanceSales/SavePlanIncentivo`;

    callApi(urlWebServiceSavePlanIncentivo, 'POST', paramsServicePlanIncentivo, (response) => {
      showSweetAlert('success', t('common:SuccessOperation'), 'success');
      onHideAP();
    });
  };

  const valitationSchemeSave = yup.object().shape({
    ClaAgente: yup.mixed().required(t('common:RequiredField')).nullable(),
    ClaTipoAgente: yup.mixed().required(t('common:RequiredField')).nullable(),
    PctIncentivo: yup.number().required(t('common:RequiredField')).nullable(),
    AnnualSPITargetIncentive: yup.number().required(t('common:RequiredField')).nullable(),
    FechaInicio: yup.date().required(t('common:RequiredField')).nullable(),
    ClaColaborador: yup.mixed().required(t('common:RequiredField')).nullable(),
    PlanIncentivoList: yup.array().of(
      yup.object().shape({
        ClaIncentivo: yup.string().when('BajaLogica', {
          is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable(),
        }),
        PctPonderado: yup.number().required(t('common:RequiredField')).nullable(),
        PctMinGoal: yup.number().required(t('common:RequiredField')).nullable(),
        PctIncentivoMin: yup.number().required(t('common:RequiredField')).nullable(),
        PctMaxGoal: yup.number().required(t('common:RequiredField')).nullable(),
        PctIncentivoMax: yup.number().required(t('common:RequiredField')).nullable(),
        ClaTipoPago: yup.string().when('BajaLogica', {
          is: 0,
          then: yup.string().trim().required(t('common:RequiredField')).nullable(),
          otherwise: yup.string().nullable(),
        }),
      })
    ),
    ClaAnio: yup.mixed().required(t('common:RequiredField')).nullable(),
  });

  const showActiveRows = (rows) => {
    const activeRows = [];
    if (rows !== undefined) {
      if (rows.length > 0) {
        rows.forEach((item, index) => {
          if (!item.BajaLogica || item.BajaLogica === 1 || item.BajaLogica === 0 || item.BajaLogica === undefined) {
            if (!item.BajaLogica || item.BajaLogica === undefined) {
              item.BajaLogica = 0;
            }
            if (item.NewRow === undefined) {
              item.NewRow = 0;
            }
            item.RowIndex = index;
            item.RowIndexCount = index + 1;
            activeRows.push({ ...item });
          }
        });
      } else {
        return [];
      }
    }

    return activeRows;
  };

  const renderFooter = () => {
    return (
      <>
        <Toolbar
          left={leftToolbarFooterNewAPTemplate}
          right={rightToolbarFooterNewAPTemplate}
        >
        </Toolbar>
      </>
    );
  };
  const leftToolbarFooterNewAPTemplate = () => {
    return (
      <>
        <FieldButton
          label={t('common:Close')}
          icon="fas fa-times"
          className="warning no-border p-mr-2 rounded-button"
          onClick={
            typeof onCloseClick !== 'undefined' && typeof onCloseClick === 'function'
              ? onCloseClick
              : null
          }
        />
      </>
    );
  };
  const rightToolbarFooterNewAPTemplate = () => {
    return (
      <>
        <FieldButton
          label={t('common:Save')}
          icon="pi pi-save"
          className="light-green no-border p-mr-2"
          onClick={onSaveClick}
        >
        </FieldButton>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <Row className="text-center">
          <Col lg={12} md={12} sm={12} xs={12} className="text-center">
            {
              ClaUsuario !== null ?
              (<>{t('salesPerformanceSales:Edit')} :</>) :(<>{t('salesPerformanceSales:New')}</>)
            }
            <span>{SalesPerson} </span>
          </Col>
        </Row>
      </div>
    );
  };

  const onHideAP = () => {
    document.body.classList.remove('bodynotscroll');

    if (onCloseClick !== null && typeof onCloseClick === 'function') {
      onCloseClick();
    }
  };

  const fieldsRequired = (rowData, props) => {
    return (
      <>
        {errors[`PlanIncentivoList[${props.rowData.RowIndex}]`] != null ? (
          <i className="fas fa-exclamation-triangle invalid-color"></i>
        ) : (
          ''
        )}
      </>
    );
  };

  const actionBodyTemplatePlanIncentivo = (rowData, props) => {
    return (
      <>
        <FieldButton
          icon="pi pi-trash"
          onClick={() => onConfirmBajaPlanIncentivo(rowData, props)}
          className="p-button-rounded p-button-warning"
        />
      </>
    );
  };

  const onConfirmBajaPlanIncentivo = (row, props) => {
    /* AQUI  */
    // aqui lo borro de mi arreglo
    const PlanIncentivoListDelete = [...PlanIncentivoList];

    if (PlanIncentivoListDelete[row.RowIndex].ClaUsuario === null) {
      // PlanIncentivoListDelete.splice(row.RowIndex, 1);
      PlanIncentivoListDelete[row.RowIndex].BajaLogica = 1;
    } else {
      PlanIncentivoListDelete[row.RowIndex].BajaLogica = 1;
    }
    setPlanIncentivoList(PlanIncentivoListDelete);
  };

  const emptyValueValidator = (e) => {
    const { rowData, field } = e.columnProps;
    return rowData[field].trim().length > 0;
  };

  const rowClass = (data) => {
    return {
        'displayNot': data.BajaLogica === 1
    }
  }


  return (
    <>
      <Dialog
        visible={EsVerCompensacionAg}
        modal
        style={{ width: '100vw' }}
        footer={renderFooter}
        header={renderHeader}
        onHide={onHideAP}
        closable={false}
        className="dialog-header-background-blue"
        maximized={true}
        draggable={false}
        blockScroll={true}
      >
        <Row className="align-items-start">
          <Col lg={4} md={4} sm={12} xs={12}> 
            <Row className="pt-5 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>                
                <div className="p-fluid">
                  <FieldDropdown
                    name="ClaAnio"
                    value={ClaAnio}
                    options={OpcionesAnio}
                    optionValue="ClaAnio"
                    optionLabel="NomAnio"
                    label={t('salesPerformanceSales:Anio')}
                    onChange={(e) => onClaAnioChange(e)}
                    showClear={false}
                    disabled={true}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>                
                <div className="p-fluid">
                  <FieldDropdown
                    name="ClaAgente"
                    value={ClaAgente}
                    options={OpcionesClaAgenteAg}
                    optionValue="ClaAgente"
                    optionLabel="NomAgente"
                    label={t('salesPerformanceSales:SalesPerson')}
                    onChange={(e) => onClaAgenteChange(e.value)}
                    disabled={ClaUsuario !== null}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>                
                <div className="p-fluid">
                  <FieldAutoComplete
                    dropdown
                    name="ClaColaborador"
                    label={t('salesPerformanceSales:Responsible')}
                    value={ClaColaborador}
                    onChange={onChangeClaUsuarioClaColaborador}
                    suggestions={filteredResponsible}
                    field="NomColaborador"
                    completeMethod={filterResponsible}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldDropdown
                    name="ClaTipoAgente"
                    value={ClaTipoAgente}
                    options={OpcionesTipoAgente}
                    optionValue="ClaTipoAgente"
                    optionLabel="NomTipoAgente"
                    label={t('salesPerformanceSales:SalesType')}
                    onChange={(e) => onClaTipoAgenteChange(e.value)}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldNumber
                    name="PctIncentivo"
                    value={PctIncentivo}
                    onChange={(e) => onPctIncentivoChange(e.target.value)}
                    min={-99999999.99}
                    max={99999999.99}
                    label={t('salesPerformanceSales:IncentivePct')}
                    mode="decimal"
                    locale="en-US"
                    minFractionDigits={2}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldNumber
                    name="AnnualSPITargetIncentive"
                    value={AnnualSPITargetIncentive}
                    onChange={(e) => onAnnualSPITargetIncentiveChange(e.target.value)}
                    min={-99999999.99}
                    max={99999999.99}
                    label={t('salesPerformanceSales:AnnualSPITargetIncentive')}
                    mode="decimal"
                    locale="en-US"
                    minFractionDigits={2}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldCalendar
                    name="FechaInicio"
                    value={FechaInicio !== null ? new Date(FechaInicio) : null}
                    onChange={(e) => onChangeFechaInicio(e.target.value)}
                    label={t('salesPerformanceSales:StartingDate')}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldTextArea
                    name="Comentario"
                    rows={5}
                    placeholder=""
                    maxLength={250}
                    value={Comentario}
                    onChange={(e) => ComentarioChange(e.target.value)}
                    label={t('salesPerformanceSales:Observation')}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldCalendar
                    name="FechaBaja"
                    value={FechaBaja !== null ? new Date(FechaBaja) : null}
                    onChange={(e) => onChangeFechaBaja(e.target.value)}
                    label={t('salesPerformanceSales:StopDate')}
                    errors={errors}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <div className="p-fluid">
                  <FieldTextArea
                    name="ComentarioBaja"
                    rows={5}
                    placeholder=""
                    maxLength={250}
                    value={ComentarioBaja}
                    onChange={(e) => ComentarioBajaChange(e.target.value)}
                    label={t('salesPerformanceSales:StopReason')}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <Row className="pt-5 pb-3">
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(PlanIncentivoList)}
                  scrollable
                  className="p-datatable-striped"
                  selectionMode="single"
                  metaKeySelection={false}
                  header={header}
                  paginator={false}
                  footerColumnGroup={footer}
                  rowClassName={rowClass}
                >
                  <Column
                    body={(rowData, props) => fieldsRequired(rowData, props)}
                    headerStyle={{ width: '20px' }}
                  >
                  </Column>
                  <Column
                    body={(rowData, props) => actionBodyTemplatePlanIncentivo(rowData, props)}
                    headerStyle={{ width: '35px' }}
                  >
                  </Column>
                  <Column
                    field="NomIncentivo"
                    columnKey="ClaIncentivo"
                    header={t('salesPerformanceSales:IncentiveType')}
                    headerStyle={{ width: '105px' }}
                    editor={(props) => ClaIncentivoEditor(props)}
                    // editorValidator={emptyValueValidator}
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.PctPonderado)}
                    field="PctPonderado"
                    columnKey="PctPonderado"
                    header={t('salesPerformanceSales:PctPonderado')}
                    headerStyle={{ width: '85px' }}
                    editor={(props) => PctPonderadoEditor(props)}
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.PctMinGoal)}
                    field="PctMinGoal"
                    columnKey="PctMinGoal"
                    header={t('salesPerformanceSales:PctMinGoal')}
                    headerStyle={{ width: '85px' }}
                    editor={(props) => PctMinGoalEditor(props)}
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.PctIncentivoMin)}
                    field="PctIncentivoMin"
                    columnKey="PctIncentivoMin"
                    header={t('salesPerformanceSales:PctIncentivoMin')}
                    headerStyle={{ width: '85px' }}
                    editor={(props) => PctIncentivoMinEditor(props)}
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.PctMaxGoal)}
                    field="PctMaxGoal"
                    columnKey="PctMaxGoal"
                    header={t('salesPerformanceSales:PctMaxGoal')}
                    headerStyle={{ width: '85px' }}
                    editor={(props) => PctMaxGoalEditor(props)}
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.PctIncentivoMax)}
                    field="PctIncentivoMax"
                    columnKey="PctIncentivoMax"
                    header={t('salesPerformanceSales:PctIncentivoMax')}
                    headerStyle={{ width: '85px' }}
                    editor={(props) => PctIncentivoMaxEditor(props)}
                  >
                  </Column>
                  <Column
                    field="NomTipoPago"
                    columnKey="ClaTipoPago"
                    header={t('salesPerformanceSales:PaymentType')}
                    headerStyle={{ width: '105px' }}
                    editor={(props) => ClaTipoPagoEditor(props)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <FieldDataTable
                  value={showActiveRows(CompensationAgAnualTargetDet)}
                  scrollable
                  className="p-datatable-striped" 
                  metaKeySelection={false} 
                  paginator={false} 
                  header={t('salesPerformanceSales:AnnualTargetByYear')}
                >  
                  <Column
                    field="Anio"
                    columnKey="Anio"
                    header={t('salesPerformanceSales:Year')}
                    headerStyle={{ width: '60px' }} 
                  >
                  </Column>
                  <Column
                    className="text-center"
                    body={(row) => renderNumericValue(row.AnnualSPITargetIncentive)}
                    field="AnnualSPITargetIncentive"
                    columnKey="AnnualSPITargetIncentive"
                    header={t('salesPerformanceSales:AnnualSPITargetIncentive')}
                    headerStyle={{ width: '100px' }} 
                  >
                  </Column> 
                  <Column
                    field="UsuarioModifico"
                    columnKey="UsuarioModifico"
                    header={t('salesPerformanceSales:UsuarioModifico')}
                    headerStyle={{ width: '230px' }}  
                  >
                  </Column>
                  <Column
                    field="FechaUltimaMod"
                    columnKey="FechaUltimaMod"
                    header={t('salesPerformanceSales:FechaUltimaMod')}
                    headerStyle={{ width: '170px' }} 
                    body={(row) => renderFecha(row.FechaUltimaMod)}
                  >
                  </Column>
                </FieldDataTable>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog>
    </>
  );
};
// IncentiveProgramSetUpEdit.prototype = {
//   propsClaUsuario: PropType.number,
// };
export default IncentiveProgramSetUpEdit;
