/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FieldButton from '../../../components/Controls/FieldButton/FieldButton.jsx';
import { Column } from 'primereact/column';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, showSweetAlert, getSessionItem } from '../../../utils/utils';
import { Checkbox } from 'primereact/checkbox';
import FieldText from '../../../components/Controls/FieldText.jsx';
import FieldTextArea from '../../../components/Controls/FieldTextArea.jsx';
import FieldNumber from '../../../components/Controls/FieldNumber.jsx';
import FieldDataTable from '../../../components/Controls/FieldDataTable/FieldDataTable.jsx';
import FieldCalendar from 'components/Controls/FieldCalendar.jsx';
import FieldDropdown from 'components/Controls/FieldDropdown.jsx';
import { RadioButton } from 'primereact/radiobutton';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import * as yup from 'yup';
import YupValidator from '../../../utils/YupValidator';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import swal from '@sweetalert/with-react';
import { Tag } from 'primereact/tag';
import PageContent from 'layouts/PageContent.jsx';
import { ColumnGroup } from 'primereact/columngroup';
import InstructionsCard from '../../../components/Cards/InstructionsCard/InstructionsCard.jsx';
import IndividualIncetiveCompensation from './IndividualIncetiveCompensation.jsx';
import IndividualIncentiveCompensationDetailByInvoice from './IndividualIncentiveCompensationDetailByInvoice.jsx';

const IndividualIncetiveCompensationScreen = (props) => {
  /* ################## useState area start ################## */
  const { t } = useTranslation(['indIncCompensation', 'common']);

  const [ClaAnio, setClaAnio] = useState(null);
  const [ClaAgenteVentas, setClaAgenteVentas] = useState(null);
  const [AniosList, setAniosList] = useState([]);
  const [AgenteVentasList, setAgenteVentasList] = useState([]);
  const [ClaMes, setClaMes] = useState(null);
  const [OpcionesMes, setOpcionesMes] = useState([]);

  useEffect(() => {
    GetAniosCmb();
    GetAgenteCmb();
    GetMesCmb();
  }, []);

  const GetAniosCmb = () => {
    const GetAniosCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAniosCmb`;
    const paramsToService = {};
    callApi(GetAniosCmb, 'GET', paramsToService, (response) => {
      setAniosList(response.data.Anios);      
      initializeYear(response.data.Anios);
    });
  };

  const GetAgenteCmb = () => {
    const GetAgenteIncentiveCmb = `${config.UrlApiProject}SalesPerformanceSales/GetAgenteIncentiveCmb?ClaAnio=${(ClaAnio===null||ClaAnio===undefined?new Date().getFullYear():ClaAnio)}`; 
    const paramsToService = { 
    };
    callApi(GetAgenteIncentiveCmb, 'GET', paramsToService, (response) => {
      setAgenteVentasList(response.data.Agente);
    });
  };

  const GetMesCmb = () => {
    const GetMesCmb = `${config.UrlApiProject}ScoreCard/GetMesCmb`;
    const paramsToService = {};
    callApi(GetMesCmb, 'GET', paramsToService, (response) => {
      setOpcionesMes(response.data.Mes);
    });
  };

  const onChangeClaAnio = (e) => {        
    const year = e.value === undefined ? null : e.value;    
    setClaAnio(year);
    changeMonth(year);
  };

  const onChangeClaMes = (e) => {    
    setClaMes(e.value);
  };

  const onChangeEsDetailInvoice = () => {
    if (ClaAnio !== null && ClaAgenteVentas !== null && ClaMes !== null) {
      setEsDetailInvoice(true);   
    }  
  };
  
  const initializeYear = (years) => {    
    if (Array.isArray(years) && years.length > 0) {
      const currentYear = new Date().getFullYear();
      const filteredYears = years.filter((year) => year.ClaAnio === currentYear);    
      const year = filteredYears.length > 0? currentYear: years[0].ClaAnio;
      setClaAnio(year);
      changeMonth(year);
    }
  };

  const changeMonth = (year) => {
    if (year) { 
      const currentYear = new Date().getFullYear(); 
      const currentMonth = new Date().getMonth() + 1;
      setClaMes(year === currentYear? currentMonth: 12);
    } else {
      setClaMes(null);
    }    
  };
  
  return (
    <>
      <PageContent title={t('indIncCompensation:IndividualIncetiveCompensationGoals')}>
        <Row hidden={true}>
          <Col lg={12} md={12} sm={12} xs={12} className="pb-2">
            <InstructionsCard>
              <p>{t('indIncCompensation:Note2')}.</p>
            </InstructionsCard>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaAnio"
                label={t('salesPerformance:Anio')}
                value={ClaAnio}
                options={AniosList}
                optionValue="ClaAnio"
                optionLabel="NomAnio"
                onChange={onChangeClaAnio}
              />
            </div>
          </Col>
          {/* 
          <Col lg={3} md={3} sm={12} xs={12}>
            <div className="p-fluid">
              <FieldDropdown
                name="ClaMes"
                value={ClaMes}
                options={OpcionesMes}
                optionValue="ClaMes"
                optionLabel="NomMes"
                label={t('salesPerformance:Month')}
                onChange={(e) => onChangeClaMes(e)}
                showClear={false}
              />
            </div>
          </Col> 
           */}
          <Col lg={9} md={9} sm={12} xs={12} className="p-3">
            <div className="p-fluid">
              <FieldDropdown
                name="ClaAgenteVentas"
                label={t('salesPerformance:SalesRep')}
                value={ClaAgenteVentas}
                options={AgenteVentasList}
                optionValue="ClaAgente"
                optionLabel="NomAgente"
                onChange={(e) => setClaAgenteVentas(e.value === undefined ? null : e.value)}
              />
            </div>
          </Col>
        </Row>
        <Row hidden={ClaAnio === null || ClaAgenteVentas === null || ClaMes === null}>
          <Col>
            <IndividualIncetiveCompensation
              ClaAnio={ClaAnio}
              ClaAgenteVentas={ClaAgenteVentas}
              ClaMes={ClaMes}
            >
            </IndividualIncetiveCompensation>
          </Col>
        </Row>       
      </PageContent>
    </>
  );
};
export default IndividualIncetiveCompensationScreen;
